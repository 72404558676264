<template>
  <!-- 用户信息页面 -->
  <v-container fluid>
    <v-card>
      <v-card-title>
        <v-tabs center-active v-model="selectedUserTypeTab">
          <v-tabs-slider color="yellow"></v-tabs-slider>
          <template v-for="(tab,i) in userTypeTabs">
          <v-tab :key="i" v-if="tab.show">
            <span class="subtitle-1">{{ tab.text }}</span>
          </v-tab>
          </template>
        </v-tabs>
      </v-card-title>
      <v-tabs-items v-model="selectedUserTypeTab">
        <!-- 普通用户 -->
        <v-tab-item class="px-5">
          <v-data-table
            :search="searchByGeneralUser"
            :headers="generalUserHeaders"
            :items="generalUserList"
          >
            <template v-slot:top>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <v-btn
                    color="primary"
                    @click="showGeneralUserDialog=true;createGeneralUser()"
                  >{{$t('lang.user.newUser')}}</v-btn>
                </v-col>
                <v-col cols="12" sm="4" offset-sm="6">
                  <v-text-field
                    v-model="searchByGeneralUser"
                    :label="$t('lang.station.searchLabel')"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn
                color="primary lighten-1"
                small
                class="mr-2"
                @click="showGeneralUserDialog=true;editGeneralUser(item)"
              >{{$t('lang.station.editBtn')}}</v-btn>
              <v-btn
                color="error lighten-1"
                small
                @click="deleteUser(item.id)"
              >{{$t('lang.station.deleteBtn')}}</v-btn>
            </template>
            <!-- <template v-slot:item.type="{}">
              {{ $t('lang.register.company')}}
            </template>
            <template v-slot:item.companyCertification="{item}">
              <span v-if="item.companyCertification=='notCertified'">{{$t('lang.user.notCertified')}}</span>
              <span v-else>{{$t('lang.user.verified')}}</span>
            </template>-->
          </v-data-table>
        </v-tab-item>

        <!-- 管理员 -->
        <v-tab-item class="px-5" v-if="$root.authority=='ROLE_ADMIN'">
          <v-data-table :search="searchByAdmin" :headers="adminHeaders" :items="adminList">
            <template v-slot:top>
              <v-row dense>
                <v-col cols="12" sm="2">
                  <v-btn
                    color="primary"
                    @click="showAdminDialog=true;createAdmin()"
                  >{{$t('lang.user.newAdministrator')}}</v-btn>
                </v-col>
                <v-col cols="12" sm="4" offset-sm="6">
                  <v-text-field
                    v-model="searchByAdmin"
                    :label="$t('lang.station.searchLabel')"
                    append-icon="mdi-magnify"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
            <template v-slot:item.actions="{item}">
              <v-btn
                color="primary lighten-1"
                small
                class="mr-2"
                @click="showAdminDialog=true;editAdmin(item)"
              >{{$t('lang.station.editBtn')}}</v-btn>
              <!-- <v-btn color="grey lighten-1" small class="mr-2">{{$t('lang.user.resetPasswordBtn')}}</v-btn> -->
              <v-btn
                color="error lighten-1"
                small
                @click="deleteUser(item.id)"
              >{{$t('lang.station.deleteBtn')}}</v-btn>
            </template>
            <template
              v-slot:item.authority="{item}"
            >{{ adminTypes.filter(adminType => adminType.value == item.authority)[0].text }}</template>
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-card>
    <!-- 普通用户信息框 -->
    <v-dialog max-width="500" v-model="showGeneralUserDialog">
      <v-card>
        <v-card-title>{{ editGeneralUserIndex == -1 ? $t('lang.user.newUser') : $t('lang.user.editUser')}}</v-card-title>
        <v-card-text>
          <v-form ref="generalUserForm">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  :disabled="editGeneralUserIndex != -1"
                  v-model="generalUserDetailInDialog.username"
                  :label="$t('lang.register.usernameLabel')"
                  :rules="usernameRules"
                  :counter="20"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-if="editGeneralUserIndex== -1"
                  v-model="generalUserDetailInDialog.password"
                  :label="$t('lang.register.passwordLabel')"
                  type="password"
                  :rules="passwordRules"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="generalUserDetailInDialog.password"
                  :label="$t('lang.register.passwordLabel')"
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="generalUserDetailInDialog.phoneNumber"
                  :label="$t('lang.register.phoneNumberLabel')"
                  :rules="phoneNumberRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showGeneralUserDialog=false">{{$t('lang.station.cancelBtn')}}</v-btn>
          <v-btn class="primary" @click="saveGeneralUser()">{{$t('lang.package.confirmBtn')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- 管理员信息框 -->
    <v-dialog max-width="500" v-model="showAdminDialog">
      <v-card>
        <v-card-title>{{ editAdminIndex == -1 ? $t('lang.user.newAdministrator') : $t('lang.user.administratorEditor') }}</v-card-title>
        <v-card-text>
          <v-form ref="adminDetailInDialog">
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  :disabled="editAdminIndex != -1"
                  v-model="adminDetailInDialog.username"
                  :label="$t('lang.register.usernameLabel')"
                  :rules="usernameRules"
                  :counter="20"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-if="editAdminIndex == -1"
                  v-model="adminDetailInDialog.password"
                  :label="$t('lang.register.passwordLabel')"
                  type="password"
                  :rules="passwordRules"
                ></v-text-field>
                <v-text-field
                  v-else
                  v-model="adminDetailInDialog.password"
                  :label="$t('lang.register.passwordLabel')"
                  type="password"
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-if="editAdminIndex == -1"
                  v-model="adminDetailInDialog.confirmPwd"
                  :label="$t('lang.register.confirmPwdLabel')"
                  type="password"
                  :rules="confirmPwdRules"
                ></v-text-field>
              </v-col>
            </v-row>-->
            <v-row dense>
              <v-col cols="12">
                <v-select
                  :items="adminTypes"
                  return-object
                  item-text="text"
                  item-value="value"
                  v-model="adminDetailInDialog.type"
                  :label="$t('lang.register.customerTypeLabel')"
                  :readonly="editAdminIndex != -1"
                ></v-select>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12">
                <v-text-field
                  v-model="adminDetailInDialog.email"
                  :label="$t('lang.user.email')"
                  :rules="emailRules"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="showAdminDialog=false">{{$t('lang.station.cancelBtn')}}</v-btn>
          <v-btn class="primary" @click="saveAdmin()">{{$t('lang.station.saveBtn')}}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      :timeout="tips.timeout"
      v-model="tips.showSnackbar"
      :color="tips.snackbarColor"
      :top="true"
    >{{ tips.snackbarText }}</v-snackbar>
  </v-container>
</template>
<script>
import axios from "axios";
import qs from "qs";
import commonCfg from "../../config/common";
let apiUrl = commonCfg.commonUrl;
let vm;

export default {
  data() {
    return {
      userTypeTabs: [
        { text: this.$t("lang.user.generalUser"), show: true },
        { text: this.$t("lang.user.administrator"), show: this.$root.authority == 'ROLE_ADMIN' },
      ],
      selectedUserTypeTab: null,
      adminHeaders: [
        { value: "username", text: this.$t("lang.register.usernameLabel") },
        {
          value: "authority",
          text: this.$t("lang.register.customerTypeLabel"),
        },
        { value: "email", text: this.$t("lang.user.email") },
        {
          value: "actions",
          text: this.$t("lang.station.operation"),
          sortable: false,
        },
      ],
      adminList: [
        // {
        //   username: "testAdmin01",
        //   type: "projectAdmin",
        //   email: "testAdmin01@email.com",
        // },
        // {
        //   username: "testAdmin02",
        //   type: "financialAdmin",
        //   email: "testAdmin02@email.com",
        // },
      ],
      searchByAdmin: "",
      showAdminDialog: false,
      adminTypes: [
        {
          text: this.$t("lang.user.projectAdministrator"),
          value: "ROLE_USER_ADMIN",
        },
        {
          text: this.$t("lang.user.financialAdministrator"),
          value: "ROLE_FINANCIAL",
        },
      ],
      adminDetailInDialog: {
        username: "",
        type: "",
        email: "",
        password: "",
        //confirmPwd: "",
        id: -1,
      },
      editAdminIndex: -1,
      usernameRules: [
        (v) => !!v || this.$t("lang.user.usernameRules.required"),
        (v) =>
          /^[\u4e00-\u9fa5_$a-zA-Z0-9]+$/.test(v) ||
          this.$t("lang.user.usernameRules.specialCharacterRestriction"),
        (v) =>
          v.length <= 20 ||
          this.$t("lang.user.usernameRules.maximumCharacterLimit"),
      ],
      passwordRules: [
        (v) => !!v || this.$t("lang.user.passwordRules.required"),
      ],
      // confirmPwdRules: [
      //   (v) => !!v || this.$t("lang.user.confirmPwdRules.required"),
      //   (v) =>
      //     v == this.adminDetailInDialog.password ||
      //     this.$t("lang.user.confirmPwdRules.passwordConsistency"),
      // ],
      emailRules: [
        (v) => !!v || this.$t("lang.user.emailRules.required"),
        (v) => /.+@.+\..+/.test(v) || this.$t("lang.user.emailRules.valid"),
      ],
      phoneNumberRules: [
        (v) => !!v || this.$t("lang.register.phoneNumberRules.required"),
        (v) =>
          /^1[3456789]\d{9}$/.test(v) ||
          this.$t("lang.register.phoneNumberRules.formatValidation"),
      ],
      generalUserList: [],
      generalUserHeaders: [
        { value: "username", text: this.$t("lang.register.usernameLabel") },
        { value: "phone", text: this.$t("lang.register.phoneNumberLabel") },
        {
          value: "actions",
          text: this.$t("lang.station.operation"),
          sortable: false,
        },
      ],
      showGeneralUserDialog: false,
      searchByGeneralUser: "",
      generalUserDetailInDialog: {
        username: "",
        phoneNumber: "",
        password: "",
        id: "",
      },
      editGeneralUserIndex: -1,
      tips: {
        showSnackbar: false,
        snackbarColor: "error",
        snackbarText: "",
        timeout: -1,
      },
    };
  },
  created() {
    vm = this;
    this.getUserList();
    console.log(this.$root.authority);
  },
  methods: {
    //通知提示工具
    showTips(text, color, timeout) {
      this.tips.showSnackbar = true;
      this.tips.snackbarText = text;
      this.tips.snackbarColor = color;
      if (timeout) {
        this.tips.timeout = timeout;
      } else {
        this.tips.timeout = 3000;
      }
    },
    createAdmin() {
      this.editAdminIndex = -1;
      this.adminDetailInDialog = {
        username: "",
        type: this.adminTypes[0],
        email: "",
        password: "",
        //confirmPwd: "",
        id: -1,
      };
      if (this.$refs.adminDetailInDialog)
        this.$refs.adminDetailInDialog.resetValidation();
    },
    editAdmin(item) {
      this.adminDetailInDialog = {
        username: item.username,
        type: item.authority,
        email: item.email,
        id: item.id,
        password: "",
      };
      this.editAdminIndex = this.adminList.findIndex(
        (admin) => admin.username == item.username
      ); //从管理员列表里计算得出
    },
    saveAdmin() {
      if (!this.$refs.adminDetailInDialog.validate()) return;
      this.showAdminDialog = false;
      let param = {};
      if (this.adminDetailInDialog.id != -1) {
        //编辑
        if (this.adminDetailInDialog.password == "") {
          param["id"] = this.adminDetailInDialog.id;
          param["email"] = this.adminDetailInDialog.email;
        } else {
          param["id"] = this.adminDetailInDialog.id;
          param["email"] = this.adminDetailInDialog.email;
          param["password"] = this.adminDetailInDialog.password;
        }
      } else {
        param["email"] = this.adminDetailInDialog.email;
        param["password"] = this.adminDetailInDialog.password;
        param["authority"] = this.adminDetailInDialog.type.value;
        param["username"] = this.adminDetailInDialog.username;
      }
      console.log(param);
      this.editUser(param);
    },
    //获得用户列表
    getUserList() {
      this.adminList = [];
      this.generalUserList = [];
      axios
        .get(`${apiUrl}admin/user/list`)
        .then((res) => {
          console.log(res.data);
          if (res.status == 200) {
            vm.adminList = res.data.filter(
              (data) =>
                data.authority != "ROLE_USER" && data.authority != "ROLE_ADMIN"
            );
            vm.generalUserList = res.data.filter(
              (data) => data.authority == "ROLE_USER"
            );
          }
        })
        .catch((error) => {
          console.log(error);
          vm.adminList = [];
          vm.generalUserList = [];
        });
    },
    createGeneralUser() {
      this.editGeneralUserIndex = -1;
      this.generalUserDetailInDialog = {
        username: "",
        password: "",
        phoneNumber: "",
        id: -1,
      };
      if (this.$refs.generalUserForm)
        this.$refs.generalUserForm.resetValidation();
    },
    editGeneralUser(item) {
      this.generalUserDetailInDialog = {
        username: item.username,
        phoneNumber: item.phone,
        password: "",
        id: item.id,
      };
      this.editGeneralUserIndex = this.generalUserList.findIndex(
        (user) => user.username == item.username
      );
    },
    saveGeneralUser() {
      if (!this.$refs.generalUserForm.validate()) return;
      this.showGeneralUserDialog = false;
      let param = {};
      if (this.generalUserDetailInDialog.id != -1) {
        //编辑
        if (this.generalUserDetailInDialog.password == "") {
          param["id"] = this.generalUserDetailInDialog.id;
          param["phone"] = this.generalUserDetailInDialog.phoneNumber;
        } else {
          param["id"] = this.generalUserDetailInDialog.id;
          param["phone"] = this.generalUserDetailInDialog.phoneNumber;
          param["password"] = this.generalUserDetailInDialog.password;
        }
      } else {
        //新建
        param["phone"] = this.generalUserDetailInDialog.phoneNumber;
        param["password"] = this.generalUserDetailInDialog.password;
        param["username"] = this.generalUserDetailInDialog.username;
        param["authority"] = "ROLE_USER";
      }
      console.log(param);
      this.editUser(param);
    },
    //编辑用户
    editUser(param) {
      axios
        .post(`${apiUrl}admin/user/edit`, param)
        .then((res) => {
          console.log(res);
          if (res.status == 200) {
            this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
            vm.getUserList();
            return;
          }
          this.showTips(vm.$t("lang.tips.operationFailed"), "error");
        })
        .catch((error) => {
          console.log(error);
          this.showTips(vm.$t("lang.tips.operationFailed"), "error");
        });
    },
    deleteUser(id) {
      //删除确认
      this.$dialog
        .confirm({
          text: this.$t("lang.tips.userList.delTips"),
          title: this.$t("lang.tips.userList.delConfirm"),
          cancel: this.$t("lang.station.cancelBtn"),
          actions: [
            {
              text: this.$t("lang.station.cancelBtn"),
              key: "false",
            },
            {
              text: this.$t("lang.package.confirmBtn"),
              color: "blue",
              key: true,
            },
          ],
        })
        .then(async (type) => {
          if (!type) return;
          //删除用户
          axios
            .post(
              `${apiUrl}admin/user/del`,
              qs.stringify({
                userId: id,
              })
            )
            .then((res) => {
              console.log(res);
              if (res.status == 200) {
                this.showTips(vm.$t("lang.tips.operationSuccess"), "success");
                vm.getUserList();
                return;
              }
              this.showTips(vm.$t("lang.tips.operationFailed"), "error");
            })
            .catch((error) => {
              console.log(error);
              this.showTips(vm.$t("lang.tips.operationFailed"), "error");
            });
        });
    },
  },
};
</script>